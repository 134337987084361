import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import RootState from '@vue-storefront/core/types/RootState';
import VarusCafeState from '../types/VarusCafeState'
import { getCurrentShopId } from 'theme/store/checkout/helpers';
import { buildFilterProductsQuery, buildFilterAvailableQuery } from '../helpers';
import modulesConfig from '$modules/config';
import { products, attributes } from 'config';
import {
  CATEGORY_SET_SEARCH_PRODUCTS_STATS
} from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { SET_PRODUCT_AGGREGATION } from 'theme/store/category-extension/store/mutation-types';
import { router } from '@vue-storefront/core/app';
import { parseCategoryPath } from '@vue-storefront/core/modules/breadcrumbs/helpers'
import { SearchQuery } from 'storefront-query-builder';
import { isServer } from '@vue-storefront/core/helpers'
import { aggregationRemap } from 'theme/helpers/product';
import { applyFilterFromToByDatetime, setRequestCacheTags } from '$modules/banner/helpers';
import { BannerService } from '$modules/banner/data-resolver/BannerService';
import { checkIfDateExpired } from 'theme/helpers/date';
import { Logger } from '@vue-storefront/core/lib/logger';

const actions: ActionTree<VarusCafeState, RootState> = {
  async loadCategoryProducts (
    {
      commit,
      dispatch,
      rootGetters,
      state
    },
    {
      route,
      category,
      pageSize = 50,
      start = 0
    } = {}
  ) {
    const isAdditionalLoading = state.isAdditionalLoading;
    const { query: routerQuery } = route;
    const slug = route.params?.slug ? route.params.slug : ''

    if (!isServer) {
      await dispatch('loadCategoryFilters', { category, slug });
    }

    const searchCategory = category || rootGetters['category-next/getCategoryFrom'](route.path) || {}
    const shopId = await getCurrentShopId()
    const mappedFilters = rootGetters['category-extension/getFiltersMap'][searchCategory.id]
    const routerFiltersSource = route[products.routerFiltersSource]
    const areFiltersInQuery = !!Object.keys(routerFiltersSource).length

    if (!mappedFilters && areFiltersInQuery) { // loading all filters only when some filters are currently chosen and category has no available filters yet
      await dispatch('loadCategoryFilters', searchCategory)
    }

    const searchQuery = rootGetters['category-extension/getCurrentFiltersFrom'](routerFiltersSource, mappedFilters)
    const { filters: chosenFilters, sort, stock_shop } = searchQuery;

    const filterQr = buildFilterProductsQuery({ currentCategory: searchCategory, chosenFilters, shopId, route })
    const filterAvailableQr = buildFilterAvailableQuery(searchCategory, null)
    const onlyInStock = stock_shop;
    const hasPromoFilter = route.query.hasOwnProperty([attributes.filterIsPromo.type]);
    const hasPizzaFilter = route.query.hasOwnProperty([attributes.filterPizzaIngredient.type]);

    if (hasPromoFilter) {
      filterQr.applyFilter({
        key: 'has_promotion_in_stores',
        value: { 'in': [0, shopId] },
        scope: 'catalog'
      })
    }

    if (hasPizzaFilter) {
      filterQr.applyFilter({
        key: 'pizzaIngredient',
        value: { 'eq': 1 },
        scope: 'catalog'
      })
    } else {
      filterQr.applyFilter({
        key: 'pizzaIngredient',
        value: { 'nin': null },
        scope: 'default'
      })
    }

    if (slug.length) {
      const slugId = rootGetters['category/getCategoryBySlug'](slug).id

      filterQr.applyFilter({ key: 'category_ids', value: { in: slugId } })
      filterAvailableQr.applyFilter({ key: 'category_ids', value: { in: slugId } })
    } else {
      filterQr.applyFilter({ key: 'category_ids', value: { eq: searchCategory.id } })
      filterAvailableQr.applyFilter({ key: 'category_ids', value: { eq: searchCategory.id } })
    }

    const sortOrder = sort || routerQuery.sort || 'popularity:desc';

    const { items, perPage, total, aggregations } = await dispatch('product/findProducts', {
      query: filterQr,
      sort: sortOrder,
      start,
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields,
      skipLoadOptions: true,
      size: pageSize,
      onlyInStock: onlyInStock,
      options: {
        populateRequestCacheTags: true,
        prefetchGroupProducts: false,
        setProductErrors: false,
        fallbackToDefaultWhenNoAvailable: true,
        assignProductConfiguration: false,
        separateSelectedVariant: false
      }
    }, { root: true })

    const toPromise = [
      dispatch('product/categoryProductsInStock', {
        query: filterAvailableQr
      }, { root: true })
    ]

    const [available] = await Promise.all(toPromise)

    if (isAdditionalLoading) {
      commit(types.ADD_PRODUCTS, items)
    } else {
      commit(types.SET_PRODUCTS, items)
    }

    dispatch('ui/setPagingLoading', false, { root: true })

    commit(types.SET_AVAILABLE_PRODUCTS_STATUS, available)
    commit(`category-next/${CATEGORY_SET_SEARCH_PRODUCTS_STATS}`, { perPage, start, total }, { root: true })
    commit(`category-extension/${SET_PRODUCT_AGGREGATION}`, aggregations, { root: true })

    return items
  },
  setCategoryPromotionBannersIds ({ commit }, { categoryId, promotionBannerIds }) {
    commit(types.SET_CATEGORY_PROMOTION_BANNERS_IDS, { categoryId, promotionBannerIds })
  },
  async loadCategoriesCounts ({ commit }, { rootCategory, route }) {
    const shopId = await getCurrentShopId()
    const filterQr = buildFilterProductsQuery({ currentCategory: rootCategory, shopId, route })

    filterQr.addAvailableFilter({
      field: 'category_ids',
      scope: 'catalog',
      options: {
        size: 10000
      }
    })

    const { aggregations } = await quickSearchByQuery({
      query: filterQr,
      excludeFields: ['*']
    })

    const buckets = aggregations.agg_terms_category_ids.buckets
    const productCountsByCategory = buckets.reduce((acc, bucket) => {
      acc[bucket.key] = bucket.doc_count;
      return acc;
    }, {});
    commit(types.SET_PRODUCT_COUNTS_BY_CATEGORY, productCountsByCategory)
  },
  async setProductsLoading ({ commit }, isLoading) {
    commit(types.SET_PRODUCTS_IS_LOADING, isLoading)
  },
  async loadMore ({ commit }, value) {
    commit(types.SET_IS_ADDITIONAL_LOADING, value);
  },
  changeRouterFilterParameters (context, query) {
    router.push({ query: query })
  },
  async loadCategoryBreadcrumbs ({ dispatch }, { category, isRootPage = true, currentCategoryName }) {
    if (!category) {
      return
    }

    await dispatch('breadcrumbs/set', {
      current: isRootPage ? category.name : currentCategoryName,
      routes: isRootPage ? [] : parseCategoryPath([category])
    }, { root: true })
  },
  setCategoryDescription ({ commit }, description = '') {
    commit(types.SET_CATEGORY_DESCRIPTION, description)
  },
  setSampleSpecialOffers ({ commit }, sampleSpecialOffers = []) {
    commit(types.SET_SAMPLE_SPECIAL_OFFERS, sampleSpecialOffers)
  },
  async loadChildCategories ({ state }, { parentCategoryId }) {
    if (state.childCategories.length) {
      return state.childCategories
    }

    const query = new SearchQuery()
    const includeFields = ['pimcore_id', 'id', 'name']
    query.applyFilter({ key: 'parent_id', value: { eq: parentCategoryId } })
    const response = await quickSearchByQuery({ entityType: 'category', query: query, includeFields })
    return response.items
  },
  setChildCategories ({ commit }, childCategories = []) {
    commit(types.SET_CHILD_CATEGORIES, childCategories)
  },
  async loadCategoryFilters ({ dispatch, rootGetters }, { category, slug = '' }) {
    const shopId = await getCurrentShopId()
    const filterQr = buildFilterProductsQuery({ currentCategory: category, shopId })
    if (slug.length) {
      const slugId = rootGetters['category/getCategoryBySlug'](slug).id

      filterQr.applyFilter({ key: 'category_ids', value: { in: slugId } })
    } else {
      filterQr.applyFilter({ key: 'category_ids', value: { eq: category.id } })
    }

    const { aggregations, attributeMetadata } = await quickSearchByQuery({
      query: filterQr,
      excludeFields: ['*']
    })

    await dispatch('category-extension/loadAvailableFiltersFrom', {
      aggregations: aggregationRemap(aggregations), attributeMetadata: attributeMetadata, category
    }, { root: true })
  },
  async loadBanners ({ commit }, { ids }) {
    try {
      if (ids.length === 0) {
        return
      }

      const query = new SearchQuery();
      applyFilterFromToByDatetime(query)
      query.applyFilter({ key: 'category_ids', value: { 'in': ids } })
      query.applyFilter({ key: 'status', value: { 'eq': '1' } })
      const { items } = await BannerService.getBanners({ query })
      const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
      setRequestCacheTags(filteredItems)
      const topBanners = filteredItems.filter(item => item.position === '0')
      const middleBanners = filteredItems.filter(item => item.position === '1')
      commit(types.SET_TOP_BANNERS, topBanners)
      commit(types.SET_MIDDLE_BANNERS, middleBanners)
    } catch (err) {
      Logger.debug('Unable to load middlebanners' + err)()
    }
  }
}

export default actions
