<template>
  <li
    class="a-megamenu-item"
    :class="classNameItem"
  >
    <span
      v-if="isShowPreview && !itemId"
      class="a-megamenu-item__preview"
    />
    <span
      v-if="isClickable"
      :class="classNameLink"
      data-transaction-name="MM - Item"
      @click="onClick"
      @mouseover="onMouseOver"
      class="a-megamenu-item__link"
    >
      <img
        class="a-megamenu-item__preview"
        v-if="isShowPreview && itemId"
        loading="lazy"
        :src="getCategoryPreviewImage(itemId)"
        :alt="name"
      >
      {{ name }}
    </span>
    <router-link
      v-else
      :class="classNameLink"
      :to="link"
      data-transaction-name="MM - Go To Link"
      @mouseover.native="onMouseOver"
      @click.native="onClose"
      class="a-megamenu-item__link"
    >
      <img
        class="a-megamenu-item__preview"
        v-if="isShowPreview && itemId"
        loading="lazy"
        :src="getCategoryPreviewImage(itemId)"
        :alt="name"
      >
      {{ name }}
    </router-link>
    <slot />
  </li>
</template>

<script>
import { getCategoryImagePath } from 'theme/helpers';

export default {
  name: 'AMegaMenuItem',
  props: {
    name: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    isActive: {
      type: [Boolean, String],
      default: ''
    },
    isShowPreview: {
      type: Boolean,
      default: false
    },
    hasChildren: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    classNameLink () {
      return {
        'a-megamenu-item__link--active': this.isActive
      }
    },
    classNameItem () {
      return {
        'a-megamenu-item--has-child': this.hasChildren
      }
    },
    isClickable () {
      return this.hasChildren && this.isMobile
    }
  },
  methods: {
    onMouseOver () {
      this.$emit('mouseover');
    },
    onClick () {
      this.$emit('click')
    },
    onClose () {
      this.$store.commit('ui/closeCatalog')
      this.$emit('close')
    },
    getCategoryPreviewImage (itemId) {
      return getCategoryImagePath(itemId)
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.a-megamenu-item {
  margin: 0;
  border-bottom: 1px solid var(--line-color);

  @include for-desktop {
    border-bottom: none;

    &:last-child {
      border-top: 1px solid var(--line-color);
      padding-top: var(--spacer-sm);
      margin-top: var(--spacer-sm);
    }

    > .a-megamenu-item__link--active {
      color: var(--orange);
    }
  }

  &__link {
    font-size: var(--font-sm);
    display: block;
    color: var(--black);
  }

  &__preview {
    display: none;
    min-width: 2.25rem;
    width: 2.25rem;
    height: 2.25rem;
    background: var(--orange);
    margin: var(--spacer-2xs) var(--spacer-xs) var(--spacer-2xs) 0;
  }

  &--main {
    display: flex;
    border-bottom: none;

    & > .a-megamenu-item__link {
      border-bottom: 1px solid var(--line-color);

      @include for-desktop {
        border-bottom: none;
      }
    }

    &:first-child {
      & > .a-megamenu-item__link {
        color: var(--orange);
        border-bottom: 1px solid var(--orange);
      }
    }

    @include for-desktop {
      &:last-child {
        border-top: 0;
        margin-top: 0;
        border-top: none;
        padding-top: 0;
      }
    }

    & > .a-megamenu-item__link {
      font-size: var(--font-sm);
      position: relative;
      cursor: pointer;
      align-self: stretch;
      display: flex;
      align-items: center;
      min-height: 100%;
      flex: 1 0 auto;

      @include for-desktop {
        border-bottom: 1px solid var(--line-color);
      }
    }

    .a-megamenu-item__preview {
      display: inline-flex;
      margin-bottom: var(--spacer-6);
      margin-top: var(--spacer-6);
    }

    &.a-megamenu-item--has-child {
      & > .a-megamenu-item__link .a-megamenu-item__title {
        padding-right: 0;
      }
    }
  }

  &--has-child {
    > .a-megamenu-item__link {
      position: relative;

      @include font-icon(var(--icon-arrow-bold-right));

      &:before {
        position: absolute;
        right: var(--spacer-11);
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        color: var(--black);
        font-size: var(--font-size-8);
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        background: linear-gradient(270deg, #FFF 0%, #FFF 50%, rgba(255, 255, 255, 0.00) 100%);
        width: 40px;
        height: 17px;
        right: 0;
        z-index: 1;
      }

      &--active {
        &:before {
          color: var(--orange);
        }
      }
    }
  }

  & > .a-megamenu-item__link--active + .m-megamenu-sub__third {
    display: block;
  }

  & > .a-megamenu-item__link--active + .m-megamenu-sub__twice {
    display: block;
  }
}

</style>
