import { shipping } from 'config'

export const storageKey = 'sm/shipping'
export const storageKeyModify = 'sm/shipping/modify'

export const codes = shipping.codes

export const newPostTypes = {
  department: 'department',
  poshtomat: 'poshtomat',
  address: 'address'
}

export const shippingDefaults = {
  city: null,
  method: 'DELIVERY',
  shop: null,
  type: null,
  address: null,
  npType: 'department',
  npCity: null,
  npShop: null
}

export const newPostTypesList = [
  {
    label: 'Departments',
    value: newPostTypes.department
  },
  {
    label: 'Poshtomat',
    value: newPostTypes.poshtomat
  }
  // TODO: Enable for addresses realisation
  // {
  //   label: 'To address',
  //   value: 'address'
  // }
]

export const pickupMethods = [
  shipping.codes.pickup,
  shipping.codes.varusDrive
]

export const deliveryMethods = [
  shipping.codes.delivery,
  shipping.codes.deliveryExpress,
  shipping.codes.deliveryPriority
]

export const newPostMethods = [
  shipping.codes.newPost
]

export default {
  codes,
  pickupMethods,
  deliveryMethods,
  newPostMethods,
  newPostTypes,
  newPostTypesList
}
