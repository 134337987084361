import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from '../types/CategoryState'

export const CategoryStore: Module<CategoryState, RootState> = {
  namespaced: true,
  state: {
    list: [],
    filters: {
      available: {},
      chosen: {}
    },
    breadcrumbs: {
      routes: []
    },
    current_product_query: null,
    current_path: [], // list of categories from root to current,
    productsIsLoading: true,
    isAdditionalLoading: false,
    categoriesIsLoading: true,
    products: [],
    humanFilterMeta: {
      url: '',
      data: null
    },
    availableProducts: true,
    productCountsByCategory: {},
    categoryDescription: '',
    sampleSpecialOffers: [],
    categoryPromotionBannersIds: {},
    categoryIsNoIndexFollow: false,
    categoryProductsForPromotionCount: 0
  },
  getters,
  actions,
  mutations
}
