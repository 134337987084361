<template>
  <SfButton
    class="sf-button--pure a-account-icon"
    aria-label="Account Icon"
    data-transaction-name="Account Button"
    @click="$emit('click')"
    :class="className"
  >
    <span class="a-account-icon__caption">{{ caption }}</span>
  </SfButton>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { SfButton } from '@storefront-ui/vue';

/**
 * Атомарний компонент іконки акаунту
 * @displayName AAccountIcon
 * @example
 * <AAccountIcon
 *   :is-active="false"
 *   caption="Login"
 *   @click="handleClick"
 * />
 */
export default defineComponent({
  name: 'AAccountIcon',

  components: {
    SfButton
  },

  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    caption: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const className = computed(() => ({
      'a-account-icon--active': props.isActive
    }));

    return {
      className
    };
  },

  emits: ['click']
});
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.a-account-icon {
  @include font-icon(var(--icon-user));

  &:before {
    color: var(--black);
    font-size: var(--font-size-24);
  }

  &:hover {
    &:before {
      color: var(--orange);
    }
  }

  @include for-desktop {
    margin: 0 0 0 20px;
    position: relative;

    &--active {
      &:hover {
        &:before {
          color: var(--orange);
        }
      }
    }
  }

  &__caption {
    font-size: var(--font-sm);
    font-weight: normal;
    display: inline-flex;
    padding-left: var(--spacer-xs);

    @include for-desktop {
      display: none;
    }
  }
}
</style>
