import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { SpecialOffersState } from '../types'
import FilterVariant from 'theme/store/category-extension/types/FilterVariant';
import { toString } from 'lodash';
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers';
import { products } from '$modules/special-offers/config';
import { compareByLabel } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers';
import { getFiltersFromQuery } from 'theme/helpers/filterHelpers';
import { attributes } from 'config';

const getters: GetterTree<SpecialOffersState, RootState> = {
  getSpecialOffersCategoryId: (state, getters, rootState) => {
    const configItems = rootState['config-varus']?.config;

    if (!configItems) return null;

    const categoryConfig = configItems.find(item => item.config_path === 'categories_map_discount_category_id');

    if (!categoryConfig) return null;

    return parseInt(categoryConfig.config_value);
  },
  getSpecialOffersCategory: (state) => {
    return state.specialOffersCategory;
  },
  getCurrentCategory: (state) => {
    return state.currentCategory;
  },
  getProducts (state) {
    return state.products;
  },
  getAvailableFiltersFrom: (state, getters, rootState) => (aggregations, category = null, attributeMetadata) => {
    const filters = {};

    if (!aggregations) return filters;

    const data = [...attributeMetadata].reduce((a, c) => {
      if (!c.uri_name) return a

      a[c.attribute_code] = {
        uri_name: c.uri_name,
        options: {}
      }

      for (const option of c.options) {
        if (!option.uri_name) continue

        a[c.attribute_code].options[option.value] = option.uri_name
      }

      return a
    }, {})

    const categoryFilters = category?.filterattributes?.split(',') || [];
    const defaultFilters = products.defaultFilters.concat(categoryFilters);

    for (const attrToFilter of defaultFilters) {
      if (attrToFilter === 'price') {
        if (aggregations['agg_' + attrToFilter]) {
          filters[attrToFilter] = aggregations['agg_' + attrToFilter];
        }
        continue;
      }

      const filterOptions: FilterVariant[] = [];
      const uniqueFilterValues = new Set<string>();

      if (aggregations['agg_terms_' + attrToFilter]) {
        let buckets = [...aggregations['agg_terms_' + attrToFilter].buckets];

        if (aggregations['agg_terms_' + attrToFilter + '_options']) {
          buckets = buckets.concat(aggregations['agg_terms_' + attrToFilter + '_options'].buckets);
        }

        buckets.forEach(option => uniqueFilterValues.add(toString(option.key)));
      }

      const attributes = rootState.attribute;
      const attrType = attrToFilter;

      uniqueFilterValues.forEach(key => {
        const label = optionLabel(attributes, { attributeKey: attrType, optionId: key });

        if (label && label.trim()) {
          const group = data[attrType]
          const option = group?.options?.[key]

          const uri = option ? {
            groupUri: group?.uri_name,
            optionUri: option
          } : {}

          filterOptions.push({
            id: key,
            label: label,
            type: attrType,
            ...uri
          });
        }
      });

      filters[attrToFilter] = filterOptions.sort(compareByLabel);
    }

    filters['sort'] = Object.keys(products.sortByAttributes).map(label => ({
      label: label,
      id: products.sortByAttributes[label],
      type: 'sort'
    }));

    filters['stock_shop'] = [{
      label: attributes.filterIsOnStock.label,
      type: attributes.filterIsOnStock.type
    }];

    filters['for_np'] = [{
      label: attributes.filterForNP.label,
      type: attributes.filterForNP.type
    }];

    return filters;
  },
  getFiltersMap: state => state.filtersMap,
  getCurrentFiltersFrom: (state, getters, rootState) => (filters, categoryFilters, filtersHumanURI) => {
    const currentQuery = filters || rootState.route.query
    const availableFilters = categoryFilters || getters.getAvailableFilters
    return getFiltersFromQuery({
      availableFilters,
      filtersQuery: currentQuery,
      filtersHumanURI
    })
  },
  getAvailableFilters: (state, getters) => {
    const categoryId = Number(getters.getCurrentCategory?.id);
    return getters.getFiltersMap[categoryId] || {}
  },
  getCurrentSearchQuery: (state, getters, rootState) => getters.getCurrentFiltersFrom(rootState.route.query),
  getCurrentFilters: (state, getters) => getters.getCurrentSearchQuery.filters,
  getCurrentFiltersHF: (state, getters, rootState) => (filtersHumanURI) => (
    getters.getCurrentFiltersFrom(rootState.route.query, null, filtersHumanURI)
  ),
  getCategories: state => state.categories,
  getCategorySearchProductsStats: state => state.searchProductsStats || {},
  getCategoryProductsTotal: (state, getters) => {
    const { total } = getters.getCategorySearchProductsStats
    return total?.value || 0
  }
}

export default getters
