export const SN_CART = 'cart'
export const CART_OPEN_EDIT_MODE = SN_CART + '/CART_OPEN_EDIT_MODE'
export const CART_EDIT_MODE_SET_FILTERS = SN_CART + '/CART_EDIT_MODE_SET_FILTERS'
export const CART_EDIT_QTY = SN_CART + '/CART_EDIT_QTY'
export const CART_CLOSE_EDIT_MODE = SN_CART + '/CART_CLOSE_EDIT_MODE'
export const CART_STATES = SN_CART + '/CART_STATES'
export const CART_STOCK_RESULTS = SN_CART + '/CART_STOCK_RESULTS'
export const CART_SET_PRODUCT_COMMENTS = SN_CART + '/CART_SET_PRODUCT_COMMENTS'
export const CART_SET_IS_SYNCING = SN_CART + '/CART_SET_IS_SYNCING'
export const CART_SET_IS_SYNCING_TOTAL = SN_CART + '/CART_SET_IS_SYNCING_TOTAL'
export const CART_SET_IS_CONNECTING = SN_CART + '/CART_SET_IS_CONNECTING'
export const CART_SET_IS_PRICE_CHANGED = SN_CART + '/CART_SET_IS_PRICE_CHANGED'
export const CART_SET_SQPP = SN_CART + '/CART_SET_SQPP'
export const CART_SET_LAST_PULL = SN_CART + '/CART_SET_LAST_PULL'
export const CART_SET_REVERT_RULES = SN_CART + '/CART_SET_REVERT_RULES'
