export const SN_CATEGORY = 'category'
export const CATEGORY_UPD_CATEGORIES = SN_CATEGORY + '/UPD_CATEGORIES'
export const CATEGORY_UPD_CURRENT_CATEGORY_PATH = SN_CATEGORY + '/UPD_CURRENT_CATEGORY_PATH'
export const CATEGORY_UPD_SEARCH_PRODUCT_QUERY = SN_CATEGORY + '/UPD_SEARCH_PRODUCT_QUERY'
export const CATEGORY_ADD_AVAILABLE_FILTER = `${SN_CATEGORY}/ADD_AVAILABLE_FILTER`
export const CATEGORY_REMOVE_FILTERS = SN_CATEGORY + '/REMOVE_FILTERS'
export const CATEGORY_SET_SEARCH_OPTIONS = `${SN_CATEGORY}/SET_SEARCH_OPTIONS`
export const CATEGORY_MERGE_SEARCH_OPTIONS = `${SN_CATEGORY}/MERGE_SEARCH_OPTIONS`
export const SET_HUMAN_FILTER_META = `${SN_CATEGORY}/SET_HUMAN_FILTER_META`
export const SET_PRODUCTS_IS_LOADING = `${SN_CATEGORY}/SET_PRODUCTS_IS_LOADING`
export const SET_IS_ADDITIONAL_LOADING = `${SN_CATEGORY}/SET_IS_ADDITIONAL_LOADING`
export const SET_CATEGORIES_IS_LOADING = `${SN_CATEGORY}/SET_CATEGORIES_IS_LOADING`
export const SET_PRODUCTS = `${SN_CATEGORY}/SET_PRODUCTS`
export const ADD_PRODUCTS = `${SN_CATEGORY}/ADD_PRODUCTS`
export const AVAILABLE_PRODUCTS = `${SN_CATEGORY}/AVAILABLE_PRODUCTS`
export const SET_PRODUCT_COUNTS_BY_CATEGORY = `${SN_CATEGORY}/SET_PRODUCT_COUNTS_BY_CATEGORY`
export const SET_CATEGORY_DESCRIPTION = `${SN_CATEGORY}/SET_CATEGORY_DESCRIPTION`
export const SET_SAMPLE_SPECIAL_OFFERS = `${SN_CATEGORY}/SET_SAMPLE_SPECIAL_OFFERS`
export const SET_CATEGORY_PROMOTION_BANNERS_IDS = `${SN_CATEGORY}/SET_CATEGORY_PROMOTION_BANNERS_IDS`
export const SET_CATEGORY_IS_NOINDEX_FOLLOW = `${SN_CATEGORY}/SET_CATEGORY_IS_NOINDEX_FOLLOW`
export const SET_CATEGORY_PRODUCTS_FOR_PROMOTION_COUNT = 'SET_CATEGORY_PRODUCTS_FOR_PROMOTION_COUNT'
