<template>
  <component
    :is="linkComponent.tag"
    v-bind="linkComponent.bind"
    class="a-logo"
  >
    <SfImage
      v-if="logoPath"
      :src="logoPath"
      :alt="$t(defaultTitle)"
      class="sf-header__logo"
      :lazy="false"
    />
  </component>
</template>

<script>
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { SfImage } from '@storefront-ui/vue';
import { getLogoPath } from 'theme/helpers';
import { mapState } from 'vuex';

export default {
  name: 'ALogo',
  components: { SfImage },
  props: {
    isPure: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentStore: null
    }
  },
  created () {
    this.currentStore = currentStoreView()
  },
  computed: {
    ...mapState({
      varusConfig: state => state['config-varus'].config
    }),
    logoPath () {
      const logoConfig = this.varusConfig.find(item => item.config_path === 'header_logo_src');
      return logoConfig?.config_value ? getLogoPath(logoConfig.config_value) : '/assets/images/logo/logo.svg'
    },
    defaultTitle () {
      return this.currentStore?.seo?.defaultTitle || config.seo.defaultTitle
    },
    linkComponent () {
      if (this.isPure) {
        return {
          tag: 'a',
          bind: {
            href: this.localizedRoute('/'),
            title: this.$t('Home Page')
          }
        }
      }

      return {
        tag: 'router-link',
        bind: {
          to: this.localizedRoute('/'),
          title: this.$t('Home Page')
        }
      }
    }
  }
};
</script>
<style  scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-logo {
  display: inline-flex;
  min-width: 124px;

  .sf-image {
    margin: 0;
    --image-width: 124px;
    --image-height: auto;

    @media only screen and (min-width: $tablet-min) {
      --image-width: 152px;
    }
  }
}
</style>
