export const SN_BRAND = 'brand'

export const SPECIAL_OFFERS_SET_CATEGORIES = `${SN_BRAND}/SPECIAL_OFFERS_SET_CATEGORIES`
export const SET_BRAND_CATEGORY = `${SN_BRAND}/SET_BRAND_CATEGORY`
export const ADD_PRODUCTS = `${SN_BRAND}/ADD_PRODUCTS`
export const UPDATE_PRODUCTS = `${SN_BRAND}/UPDATE_PRODUCTS`

export const SET_CATEGORY_FILTERS = `${SN_BRAND}/SPECIAL_OFFERS_SET_CATEGORY_FILTERS`
export const SPECIAL_OFFERS_SET_CURRENT_CATEGORY = `${SN_BRAND}/SPECIAL_OFFERS_SET_CURRENT_CATEGORY`
export const SPECIAL_OFFERS_SET_SEARCH_PRODUCTS_STATS = `${SN_BRAND}/SPECIAL_OFFERS_SET_SEARCH_PRODUCTS_STATS`
export const SPECIAL_OFFERS_SET_CATEGORIES_IS_LOADING = `${SN_BRAND}/SPECIAL_OFFERS_SET_CATEGORIES_IS_LOADING`
export const SET_PRODUCTS_IS_LOADING = `${SN_BRAND}/SET_PRODUCTS_IS_LOADING`
export const SET_SAMPLE_PRODUCTS_CATEGORY = `${SN_BRAND}/SET_SAMPLE_PRODUCTS_CATEGORY`
export const AVAILABLE_PRODUCTS = `${SN_BRAND}/AVAILABLE_PRODUCTS`
export const SET_IS_ADDITIONAL_LOADING = `${SN_BRAND}/SET_IS_ADDITIONAL_LOADING`
export const SET_PROMOTION_BANNERS = `${SN_BRAND}/SET_PROMOTION_BANNERS`
export const SET_CATEGORY_PRODUCTS_FOR_PROMOTION_COUNT = 'SET_CATEGORY_PRODUCTS_FOR_PROMOTION_COUNT'
