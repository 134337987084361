import config from 'config'
import { Filters } from 'core/modules/catalog-next/types/Category'
import { router } from '@vue-storefront/core/app'
import { HumanFilter } from 'theme/helpers/humanFilter';

export const getSystemFilterNames: string[] = config.products.systemFilterNames

export const getFiltersFromQuery = ({
  filtersQuery = {},
  availableFilters = {},
  filtersHumanURI = {}
} = {}): { filters: Filters } => {
  const searchQuery = {
    filters: {}
  }

  const filterQueryKeys = Object.keys(filtersQuery)

  for (const filterKey of filterQueryKeys) {
    const filter = availableFilters[filterKey]
    let queryValue = filtersQuery[filterKey]

    if (!filter) continue

    if (getSystemFilterNames.includes(filterKey)) {
      searchQuery[filterKey] = queryValue
      continue
    }

    queryValue = [].concat(filtersQuery[filterKey])

    for (const singleValue of queryValue) {
      const variant = Array.isArray(filter) ? filter.find(filterVariant => filterVariant.id === singleValue) : filter
      if (!variant) continue

      if (!Array.isArray(searchQuery.filters[filterKey])) searchQuery.filters[filterKey] = []

      searchQuery.filters[filterKey].push({ ...variant, attribute_code: filterKey })
    }
  }

  const flatFilters = Object.keys(availableFilters).reduce((a, c) => {
    if (!Array.isArray(availableFilters[c])) return a

    const to = availableFilters[c]
      .filter(i => (i.groupUri && i.optionUri))
      .map(i => ({ ...i, keyFlat: c }))

    return a.concat(to)
  }, [])

  const filterHumanKeys = Object.keys(filtersHumanURI)

  for (const filterKey of filterHumanKeys) {
    const filters = filtersHumanURI[filterKey]

    for (const filterValue of filters) {
      const filter = flatFilters.find(i => i.groupUri === filterKey && i.optionUri === filterValue)

      if (!filter) continue

      const keyFlat = filter.keyFlat

      delete filter.keyFlat

      if (!Array.isArray(searchQuery.filters[keyFlat])) searchQuery.filters[keyFlat] = []

      searchQuery.filters[keyFlat].push({ ...filter, attribute_code: keyFlat })
    }
  }

  return searchQuery
}

export const getFilterLink = (filter) => {
  const hf = new HumanFilter(router.currentRoute?.fullPath)
    .clearQuery(['page'])
    .toggleFilter(filter)

  return hf.toString()
}
