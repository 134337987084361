<template>
  <AAccountIcon
    :is-active="isLoggedIn"
    :caption="caption"
    @click="handleClick"
  />
</template>

<script>
import { defineComponent, computed, getCurrentInstance } from 'vue';
import { ModalList } from 'theme/store/ui/modals';
import { getFormattedPhone } from 'theme/helpers/text';
import AAccountIcon from '../../atoms/buttons/a-account-icon.vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default defineComponent({
  name: 'MAccountButton',

  components: {
    AAccountIcon
  },

  setup () {
    const { proxy: vm } = getCurrentInstance();

    const isLoggedIn = computed(() => vm.$store.getters['user/isLoggedIn']);
    const currentUser = computed(() => vm.$store.state.user.current);

    const formattedPhone = computed(() => {
      const phone = currentUser.value?.phone || '';
      return getFormattedPhone(phone);
    });

    const caption = computed(() => {
      const defaultCaption = vm.$t('Login or register');
      if (!isLoggedIn.value) return defaultCaption;
      return currentUser.value?.firstname || formattedPhone.value || defaultCaption;
    });

    const handleClick = () => {
      vm.$store.dispatch('ui/hideBottomNavigation', false);
      vm.$store.commit('ui/closeMenu');

      if (isLoggedIn.value) {
        vm.$router.push(localizedRoute('/my-account'));
      } else {
        vm.$store.dispatch('ui/openModal', {
          name: ModalList.Auth,
          payload: 'auth'
        });
      }
    };

    return {
      isLoggedIn,
      caption,
      handleClick
    };
  }
});
</script>
