import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'

const mutations: MutationTree<ProductState> = {
  [types.SET_TOP_PRODUCTS] (state, products) {
    state.topProducts = products
  },
  [types.SET_NEW_PRODUCTS] (state, products) {
    state.newProducts = products
  },
  [types.SET_RECOMMENDED_PRODUCTS] (state, products) {
    state.recommendedProducts = products
  }
}

export default mutations
