import debounce from 'lodash/debounce';
import config from 'config'
import { SyncPayload } from 'theme/store/cart/types/SyncTypes';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { createDiffLog } from '@vue-storefront/core/modules/cart/helpers';
import { Logger } from '@vue-storefront/core/lib/logger';

const state = {
  isBlocked: false,
  debounce: null,
  name: null,
  queue: []
}

export const SKIP_SYNC = 'SKIP_SYNC'

const waitAnswer = async (name) => new Promise((resolve) => {
  return EventBus.$once(`sync-${name}`, (data) => resolve(data))
})

const buildQueuePayload = (params: SyncPayload, force = false) => {
  const paramsBuild: SyncPayload = {
    keepCart: params?.keepCart || false,
    forceClientState: params?.forceClientState || false,
    dryRun: params?.dryRun || false,
    mergeQty: params?.mergeQty || false,
    forceSync: params?.forceSync || false,
    forceShippingSave: params?.forceShippingSave || false,
    skipPull: params?.skipPull || false
  }

  if (force) paramsBuild.forceID = Date.now()

  const name = Object.keys(paramsBuild).map(k => paramsBuild[k] ? 1 : 0).join(':')

  return {
    name,
    params: paramsBuild
  }
}

const initDebounce = (dispatch, payload) => {
  const handler = async (payload) => {
    state.isBlocked = true

    try {
      const data = await dispatch('syncHandler', payload.params)

      if (data === SKIP_SYNC) { return }

      EventBus.$emit(`sync-${payload.name}`, data)
    } catch (e) {
      Logger.error(e, 'cart')();
    } finally {
      goToNext(dispatch)
    }
  }

  state.name = payload.name
  state.debounce = debounce(handler, config.cart.syncDebounce)
  state.debounce(payload)
}

export const clearSyncQueue = () => {
  const queue = state.queue

  state.queue = []

  for (const item of queue) {
    EventBus.$emit(`sync-${item.name}`, createDiffLog())
  }
}

const goToNext = (dispatch) => {
  if (!state.queue.length) {
    state.isBlocked = false
    state.debounce = null
    state.name = ''

    return
  }

  const payload = state.queue.pop()

  initDebounce(dispatch, payload)
}

const getQueueItem = (params: SyncPayload, force = false) => {
  const payload = buildQueuePayload(params, force)

  return {
    payload,
    item: state.queue.find(i => i.name === payload.name)
  }
}

export const hasInQueue = (params: SyncPayload) => {
  const { item } = getQueueItem(params)

  return !!item
}

export const hasInQueueByParam = (param: string) => {
  return !!state.queue.find(i => i.params[param])
}

export const addToQueue = async (params: SyncPayload, force = false) => {
  const { payload, item } = getQueueItem(params, force)

  if (item && !force) {
    return waitAnswer(item.name)
  }

  state.queue.push(payload)

  return waitAnswer(payload.name)
}

export const syncQueue = (dispatch, params: SyncPayload) => {
  const payload = buildQueuePayload(params)

  if (!state.isBlocked && state.debounce && payload.name === state.name) {
    state.debounce(payload)

    return waitAnswer(payload.name)
  }

  if (state.debounce || state.isBlocked) {
    return addToQueue(params)
  }

  initDebounce(dispatch, payload)

  return waitAnswer(payload.name)
}
