import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks';
import { SyncPayload } from 'theme/store/cart/types/SyncTypes';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

const connectActions = {
  async checkDiffCarts (_, { serverItems, clientItems }) {
    if (!clientItems?.length || !serverItems?.length) {
      return false
    }

    for (const clientItem of clientItems) {
      const serverItem = serverItems.find(item => isSkuEqual(item, clientItem))

      if (!serverItem) {
        return true
      }
    }

    for (const serverItem of serverItems) {
      const clientItem = clientItems.find(item => isSkuEqual(item, serverItem))

      if (!clientItem) {
        return true
      }
    }

    return false
  },

  async authorize ({ dispatch, getters, rootGetters }, { restore = false }) {
    const coupon = getters.getCoupon.code

    const toPromise = []

    if (config.cart.serverMergeByDefault) {
      // await dispatch('loadCartIsSyncing')
      await dispatch('updateCartSyncing', true)

      const [CartPull] = await Promise.all([
        dispatch('cart/cartPull', { emptyQuote: true, skipShippingCheck: true }, { root: true }),
        dispatch('ui/syncAdultConfirmation', null, { root: true })
      ])

      const gifts = CartPull.totals.items.filter(i => i?.extension_attributes?.promotion?.is_gift).map(i => i.item_id)

      const { serverItems, clientItems } = cartHooksExecutors.beforeSync({
        clientItems: getters.getCartItems.filter(i => !i?.gift?.isGift),
        serverItems: CartPull.result.filter(i => !gifts.includes(i.item_id))
      })

      await dispatch('cart/processCartSQPP', CartPull.result, { root: true })
      await dispatch('cart/overrideTotalsFromPayload', {
        totals: CartPull.totals,
        result: CartPull.result
      }, { root: true })

      // if (state.isSyncing) {
      //   await Promise.all(toPromise)
      //   await dispatch('updateCartSyncing', false)
      //
      //   return dispatch('connect', {
      //     forceClientState: true,
      //     isLogin: !restore
      //   });
      // }

      toPromise.push(dispatch('checkDiffCarts', { serverItems, clientItems }))

      const promiseResult = await Promise.all(toPromise)
      const hasDiffInCarts = promiseResult.pop();

      await dispatch('updateCartSyncing', false)

      const forceClientState = (clientItems?.length && !serverItems?.length)

      if (hasDiffInCarts && !restore) {
        if (rootGetters['user/isLoggedIn']) {
          EventBus.$emit('cart-authorize-diff-items', {
            serverItems,
            clientItems,
            coupon,
            isLogin: !restore
          })
        }

        return
      } else if ((!clientItems?.length && serverItems?.length) || forceClientState) {
        await dispatch('connect', {
          mergeQty: false,
          isLogin: !restore,
          forceClientState: forceClientState
        })
        EventBus.$emit('cart-after-connect')

        return
      }

      await dispatch('connect', { mergeQty: false, isLogin: !restore })
      EventBus.$emit('cart-after-connect')

      return
    }

    await dispatch('connect', { mergeQty: false, isLogin: !restore })
    EventBus.$emit('cart-after-connect')
  },
  async connect ({ getters, dispatch }, {
    forceClientState = false,
    mergeQty = false,
    isLogin = false
  }) {
    if (!getters.isCartSyncEnabled) return

    const toSync: SyncPayload = {
      keepCart: isLogin,
      forceClientState,
      dryRun: !config.cart.serverMergeByDefault,
      mergeQty,
      skipPull: isLogin
    }

    return dispatch('cart/sync', toSync, { root: true })
  },
  async create ({ dispatch, getters, rootGetters }, payload) {
    const { sync } = payload || { sync: false }

    const storedItems = getters['getCartItems'] || []
    const cartToken = getters['getCartToken']
    if (storedItems.length && !cartToken) {
      Logger.info('Creating server cart token', 'cart')()
      return dispatch('connect', {})
    }

    if (sync && cartToken && !rootGetters['user/isLoggedIn'] && storedItems.length) {
      return dispatch('sync', {})
    }
  }
}

export default connectActions
