const uriSplitter = '~'
const uriParamSplitter = '_'
const uriValueSplitter = '_'

export class HumanFilter {
  private slug:any = ''
  private uri:any = {}
  private query:{ [key: string]: string[] } = {}
  private count = 0

  constructor (uri: string) {
    this.uri = uri || ''
    this.internalParse()
  }

  getSlug (slash = false) {
    if (!slash) return this.slug

    return `/${this.slug}`
  }

  getUri () {
    return this.uri
  }

  getQuery () {
    return this.query
  }

  getUriOptions () {
    return Object.keys(this.uri).reduce((a, c) => {
      a.push(...this.uri[c].map(i => ({
        groupUri: c,
        optionUri: i
      })))

      return a
    }, [])
  }

  private internalParseQuery (query: string) {
    if (!query) return {}

    const result: { [key: string]: string[] } = {}
    const querySplited = query.split('&')

    for (const param of querySplited) {
      const [key, val] = param.split('=')

      this.count += 1

      if (result[key]) {
        result[key].push(val)
        continue
      }

      result[key] = [val]
    }

    return result
  }

  private internalParse () {
    if (!this.uri) return

    const [uri, query] = this.uri.split('?')

    const { slug, path } = uri.split(uriSplitter).reduce((a, c, index) => {
      if (!c) return a

      if ((!a.path.length && !c.includes(uriParamSplitter)) || index === 0) {
        a.slug.push(c.replace(/^\//g, ''))

        return a
      }

      a.path.push(c)

      return a
    }, { slug: [], path: [] })

    const url = path.join(uriSplitter).split(uriSplitter).reduce((a: {slug: string[], uri: any}, c: string) => {
      if (!c.includes(uriParamSplitter)) return a

      const [key, ...value] = c.split(uriParamSplitter)

      a.uri[key] = uriParamSplitter === uriValueSplitter
        ? value
        : decodeURIComponent(value[0]).split(uriValueSplitter)

      this.count += a.uri[key]?.length || 0

      return a
    }, { uri: {} })

    this.uri = url.uri
    this.slug = slug.join(uriSplitter)
    this.query = this.internalParseQuery(query || '')
  }

  addToUri (groupUri: string, optionUri: string) {
    if (!this.uri[groupUri]) {
      this.uri[groupUri] = [optionUri]
      return
    }

    if (this.uri[groupUri].includes(optionUri)) return

    this.uri[groupUri].push(optionUri)
  }

  addToQuery (key: string, val: string) {
    if (!this.query[key]) this.query[key] = [val]

    if (this.query[key].includes(val)) return

    this.query[key].push(val)
  }

  addFilter (filter: any) {
    if (filter?.groupUri && filter?.optionUri) {
      this.addToUri(filter?.groupUri, filter?.optionUri)
    } else {
      this.addToQuery(filter?.type, filter?.id)
    }

    return this
  }

  toggleUri (groupUri: string, optionUri: string) {
    if (!this.uri[groupUri]) {
      this.uri[groupUri] = [optionUri]
      return
    }

    if (!this.uri[groupUri].includes(optionUri)) {
      this.uri[groupUri].push(optionUri)
      return
    }

    this.uri[groupUri] = this.uri[groupUri].filter((i: string) => i !== optionUri)

    if (!this.uri[groupUri].length) delete this.uri[groupUri]
  }

  toggleToQuery (key: string, val: any) {
    if (!this.query[key]) {
      this.query[key] = [val]
      return
    }

    if (!this.query[key].includes(val)) {
      this.query[key].push(val)
      return
    }

    this.query[key] = this.query[key].filter((i: string) => i !== val)

    if (!this.query[key].length) delete this.query[key]
  }

  toggleFilter (filter: any) {
    if (filter?.groupUri && filter?.optionUri) {
      this.toggleUri(filter?.groupUri, filter?.optionUri)
    } else {
      this.toggleToQuery(filter?.type, filter?.id)
    }

    return this
  }

  setQuery (key: string, value: any[]) {
    this.query[key] = [...value]

    return this
  }

  clearQuery (list: string[]) {
    for (const key of list) {
      delete this.query[key]
    }

    return this
  }

  getCount () {
    return this.count
  }

  toObject () {
    return {
      uri: this.uri,
      slug: this.slug,
      query: this.query
    }
  }

  toString (slash = true) {
    const result = this.toObject()

    const uriRemap = Object.keys(result.uri)
      .sort()
      .map((key: string) => (
        `${key}${uriParamSplitter}${result.uri[key].sort().map(encodeURIComponent).join(uriValueSplitter)}`
      )).join(uriSplitter)

    const uri = uriRemap ? `${uriSplitter}${uriRemap}` : ''

    const queryKeys = Object.keys(result.query)

    const queryRemap = queryKeys.map((k: string) => (
      result.query[k].map((j:string) => j ? `${k}=${j}` : k).join('&')
    )).join('&')

    const query = queryKeys.length
      ? `?${queryRemap}` : ''

    const url = `${result.slug}${uri}${query}`

    return slash ? `/${url}` : url
  }
}
