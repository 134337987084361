import { entities } from 'config'
import { Category } from '../types/Category'

export const compareByLabel = (a, b) => {
  if (a.label < b.label) {
    return -1
  }
  if (a.label > b.label) {
    return 1
  }
  return 0
}

export const _prepareCategoryPathIds = (category: Category): string[] => {
  if (!category || !category.path) return []
  return category.path.split('/')
}

export const getSearchOptionsFromRouteParams = (params: { [key: string]: string } = {}): Record<string, string> => {
  const filterableKeys = entities.category.validSearchOptionsFromRouteParams;
  return Object.entries(params).reduce((filters, [key, value]) => {
    if (filterableKeys.includes(key)) {
      filters[key] = value;
    }
    return filters;
  }, {} as Record<string, string>);
}
