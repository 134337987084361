const products = {
  defaultFilters: [
    'price',
    'has_promotion_in_stores',
    'countrymanufacturerforsite'
  ],
  'sortByAttributes': {
    'Popularity': 'popularity:desc',
    'Latest': 'created_at:desc',
    'Price: Low to high': 'final_price:asc',
    'Price: High to low': 'final_price:desc'
  }
}

const category = {
  includeFields: [
    'filterattributes',
    'children_count',
    'id',
    'is_active',
    'level',
    'name',
    'parent_id',
    'path',
    'position',
    'brand_position',
    'product_count',
    'display_on_main_brand',
    'brand_category',
    'sku',
    'h_1',
    'meta_description',
    'meta_title',
    'url_key',
    'url_path',
    'include_in_menu',
    '*.children_data.id',
    '*.id',
    '*.name',
    '*.url_key',
    '*.url_path',
    '*.slug',
    '*.product_count',
    '*.position',
    '*.path',
    '*.include_in_menu',
    '*.brand_id',
    'pimcore_id',
    'pim_brand_id',
    'is_18_plus',
    'wghweigh',
    'category',
    '*.category',
    'is_not_product_category'
  ],
  excludeFields: [
    'amountinpackage',
    'average_price',
    'barcode',
    'categoryforsearchgrid',
    'countrymanufacturer',
    'countrymanufacturerforsite',
    'dateappointment',
    'dateprocessing',
    'for*',
    'forproduct_temperature',
    'forproduct_temperaturemin',
    'is_active_in_pim',
    'is_active_in_spaceman',
    'is_new',
    'islongshelflife',
    'isrocketproduct',
    'nameut',
    'offertype',
    'packingmaterialcontent',
    'packingmaterialosr',
    'packtype',
    'sgn',
    'sqpp_score',
    'stock.backorders',
    'stock.item_id',
    'stock.low_stock_date',
    'stock.min_qty',
    'stock.product_id',
    'stock.stock_status',
    'swatch_image',
    'tsk',
    'wghdepth',
    'wghheight',
    'wghwidth',
    'related_category',
    'meta_description'
  ],
  'filterFields': {},
  'breadcrumbFilterFields': {},
  'categoriesRootCategoryId': 2,
  'categoriesVarusCafeId': 3,
  'categoriesDynamicPrefetchLevel': 2,
  'categoriesDynamicPrefetch': false,
  'validSearchOptionsFromRouteParams': [
    'url-key',
    'slug',
    'id'
  ]
}

const PAGE_SIZE = 40

export {
  products,
  PAGE_SIZE,
  category
}
