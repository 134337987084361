import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from '../types/CategoryState'
import { Category } from '../types/Category'
import { getFiltersFromQuery } from 'theme/helpers/filterHelpers';

const getters: GetterTree<CategoryState, RootState> = {
  getCurrentCategory: (state, getters) => (params: { [key: string ]: string } = {}) => {
    return getters.getCategoryBySlug(params.slug)
  },
  getCurrentRootCategory: (state, getters) => (params: { [key: string ]: string } = {}) => {
    const path = getters.getCurrentCategory(params).path
    const rootCategoryId = path && path.split('/')[2]
    return getters.getCategoriesMap[rootCategoryId]
  },
  getCategoryBySlug: (state, getters) => (slug: string) => {
    const categories = Object.values(getters.getCategoriesMap) as Category[]
    return categories.find(category => category.slug === slug) || {}
  },
  getCategoryById: (state, getters) => (id: number | string) => {
    const categories = getters.getCategoriesMap as Category[]
    return categories[id] || {}
  },
  // Temporary disabled
  // getCategoryByParams: (state, getters) => (params: { [key: string]: string } = {}) => {
  //   const categories = Object.values(getters.getCategoriesMap)
  //   return categories.find(category => {
  //     const valueCheck = []
  //     const searchOptions = getSearchOptionsFromRouteParams(params)
  //     forEach(searchOptions, (value, key) => valueCheck.push(category[key] && category[key] === (category[key].constructor)(value)))
  //     return valueCheck.filter(check => check === true).length === Object.keys(searchOptions).length
  //   }) || {}
  // },
  getCurrentCategoryPath: state => state.current_path,
  getAllCategoryFilters: state => state.filters,
  getActiveCategoryFilters: state => state.filters.chosen,
  getAvailableCategoryFilters: state => state.filters.available,
  getCurrentCategoryProductQuery: state => state.current_product_query,
  getCategoryBreadcrumbs: state => state.breadcrumbs,
  getCategoriesMap: state => {
    const categoriesMap = {}

    const addCategoryToMap = (category) => {
      categoriesMap[category.id] = category
      if (category.children_data && category.children_data.length > 0) {
        category.children_data.forEach(addCategoryToMap)
      }
    }

    state.list.forEach(addCategoryToMap)

    return categoriesMap
  },
  getCurrentFiltersFrom: (state, getters, rootState) => (filters, category, filtersHumanURI) => {
    const currentQuery = filters || { }
    const availableFilters = rootState['category-extension']?.filtersMap[category.id] || {}
    return getFiltersFromQuery({ availableFilters, filtersQuery: currentQuery, filtersHumanURI })
  },
  getCurrentSearchQuery: (state, getters) => (route, category, filtersHumanURI) => (
    getters.getCurrentFiltersFrom(route?.query, category, filtersHumanURI)
  )
}

export default getters
